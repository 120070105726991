.page-title {
  padding: 4.125rem 0 4.125rem 0;
  background-color: $color-dark;
  @media screen and (max-width: 767px) {
    padding: $space-1 0 $space-1 0;
  }

  &--productos {
    background: url("../../dist/assets/site/Frutas-Foix-page-title.jpeg") center
      center no-repeat;
    background-size: cover;
    position: relative;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent
      linear-gradient(82deg, #000000 0%, #000000fd 0%, #00000000 100%) 0% 0%
      no-repeat padding-box;
    opacity: 0.4;
  }

  &__content {
    position: relative;
    z-index: 3;
  }

  &__h {
    color: $color_white;
    font-weight: $font-weight-bold;
    font-size: $header-1;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    color: $color-accent-light;
    a {
      text-decoration: none;
      &:hover {
        color: $color-accent-light;
      }
    }
  }
}
