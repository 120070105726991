.hero {
  position: relative;
  overflow: hidden;

  &__bg {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  &__container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 45%;
    padding-top: 22rem;
    padding-bottom: 7.25rem;
    @media screen and (max-width: 1281px) {
      width: 55%;
      padding-top: 14rem;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      padding-bottom: $space-2;
    }
  }

  &__pretitulo {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $color-accent-light;
    margin-bottom: 1rem;
  }

  &__titulo {
    font-size: 4.2rem;
    font-weight: $font-weight-bold;
    color: $color-white;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 2.3rem;
    }
  }

  &__actions {
  }

  &__img {
    width: 100%;
    height: auto;
    padding: 2rem;
  }
}

.ubicaciones {
  &__content {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-right: 3rem;
    &:nth-last-child(1) {
      margin-right: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__img {
    @media screen and (max-width: 767px) {
      width: 54px;
      height: auto;
    }
  }

  &__info {
    padding-left: 2rem;
    align-self: center;
    @media screen and (max-width: 767px) {
      padding-left: 1.5rem;
    }
  }

  &__titulo {
    font-weight: $font-weight-bold;
  }

  &__descripcion {
    font-size: $font-size-small;
    color: $color-grey-3;
  }
}

.logotipos {
  &__img {
  }
}

.cl {
  background-color: $color-grey-6;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-3;
  }

  &__item {
    display: flex;
    gap: $space-3;
    &:nth-child(even) {
      @media screen and (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
      gap: $space-1;
    }
  }
  &__info {
    flex: 1 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__descripcion {
    font-size: $header-4;

    @media screen and (max-width: 991px) {
      font-size: $header-4;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
  }

  &__img {
    img {
      border-radius: 1rem;
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: $trans;
    }
  }
}

.h-productos {
  background-color: $color-black;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}

.instagram {
  &__content {
  }

  &__feed {
  }
}

.galeria-productos {
  padding: $space-2 0;
  &__galeria {
  }
  &__item {
    padding: 0 0.75rem;
  }
  &__img {
    aspect-ratio: 4 / 3;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: $border-radius;
  }
  .slick-slider {
    .slick-dots {
      // top: 2rem !important;
    }
  }
}
