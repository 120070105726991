.sbi-header {
  display: none !important;
}

.footer {
  background-color: $color-secondary-dark;
  padding: 5.2rem 0 2.3125rem 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0 2rem 0;
  }
  &__container {
    padding-right: 0;
    padding-left: 0;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;
      text-align: center;
    }
  }

  &__item {
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .widget-footer__title {
      font-size: 1rem;
      color: $color-white;
    }
  }

  &__item--logo {
    img {
      width: 250px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 196px;
      }
    }
  }

  &__item--rs {
  }
}

.copyright {
  background-color: $color-secondary-dark;
  padding: 0 0 5.2rem 0;

  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 0 0 4rem 0;
  }

  &__container {
    border-top: 1px dashed $color-white;
    padding-top: 2rem;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    gap: $space-1;
    font-size: $font-size-smaller;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__copy {
    color: $color-white;
    font-size: $font-size-smaller;
  }
  &__menu {
    .menu {
      list-style: none;
      padding-left: 0;
      display: flex;
      gap: $space-1;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }
      a {
        font-size: $font-size-smaller;
        color: $color-white;
        text-decoration: none;
        &:hover {
          color: $color-grey-5 !important;
        }
      }
    }
  }
}

.zoom-social-icons-list {
  &__item {
  }
  &__link {
    .zoom-social_icons-list-span {
      background-color: $color-white !important;
      &::before {
        color: $color-grey-1 !important;
      }
    }
  }
}
