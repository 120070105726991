@mixin button {
  padding: 1rem 1.625rem; // cambiar tamaño
  font-size: 1rem;
  border-radius: 50px; // cambiar radius
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  line-height: 1;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.625rem 1.25em;
    font-size: $font-size-small;
  }
}

.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-secondary $color-accent,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-light $color-white $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}
