.productos {
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__info {
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }

  &__item {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.25rem 1rem;
    border-radius: 4px;
    font-size: $font-size-small;
    background-color: $color-secondary;
    color: $color-white;
  }
}
