.menu-principal {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  li.menu-item {
    a {
      padding: 0.3125rem 1.5625rem;
      text-decoration: none;
      color: $color-white;
      font-size: $font-size-bigger;
      font-weight: $font-weight-medium;
    }
    &:nth-last-child(1) {
      margin-left: 1.25rem;
      @include button;
      padding: 0.625rem 1rem;

      background-color: $color-primary;
      border: none;
      &:hover {
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
        transition: $trans;
        background: darken($color-primary, 5%);
        border-color: darken($color-primary, 5%);
      }
      a {
        font-size: $font-size;
        font-weight: $font-weight-normal;
      }
    }
  }
}

.menu-overlay {
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li.menu-item {
      margin-bottom: 0.7rem;
      & > a {
        font-size: $header-4;
        font-weight: $font-weight-medium;
        text-decoration: none;
        @media screen and (max-width: 767px) {
          font-size: $header-4;
        }
      }
    }
  }
}

.nav-offcanvas {
  &__body {
  }

  &__nav {
    padding-bottom: $space-1;
    margin-bottom: $space-1;
    border-bottom: 1px dashed $color-grey-4;
  }

  &__logo {
    padding-bottom: $space-1;
    margin-bottom: $space-1;
    border-bottom: 1px dashed $color-grey-4;
    img {
      width: 140px;
      height: auto;
    }
  }
}
