.header {
  padding: 1.5rem 2rem;
  background-color: $color-dark;
  position: sticky;
  top: 0;
  z-index: 10;

  transition: $trans;
  @media screen and (max-width: 991px) {
    padding: $font-size-smaller 0;
    transition: $trans;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &--sticky {
    padding: 1rem 0;
    transition: $trans;
    box-shadow: $box-shadow-header;
    @media screen and (max-width: 991px) {
      padding: ($font-size-smaller / 3) 0;
    }
  }

  &__logo {
    margin-right: 4rem;
    img {
      width: 250px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 150px;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__burger {
    margin-left: auto;
    i {
      color: $color-white;
      font-size: 2.5rem;
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
