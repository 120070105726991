.cta {
  padding: $space-5 0;
  background-color: $color-grey-6;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &--home {
    background-color: $color-white;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 2fr;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }

  &__info {
    padding-left: 3rem;
    align-self: center;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      justify-self: center;
      text-align: center;
    }
  }

  &__pretitulo {
    font-size: $header-3;
    font-weight: $font-weight-medium;
    color: $color-dark;
    @media screen and (max-width: 991px) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
  }

  &__titulo {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-3;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
  }

  &__actions {
  }
}
