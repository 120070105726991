.contacto {
  &__content {
    color: $color-grey-3;
  }

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 8rem;
    &--form {
      grid-template-columns: 1fr 2fr;
      margin-bottom: 6rem;
      .contacto__col1 {
        @media screen and (max-width: 991px) {
          margin-bottom: 3rem;
        }
      }
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__col1 {
  }

  &__mapa {
    iframe {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-grey-3;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__direccion {
    p {
      margin-bottom: 0.6875rem;
    }
  }

  &__email {
    margin-bottom: 2rem;
    text-decoration: none;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: $font-size-small;
  }

  &__telefonos-item {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 0.2rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__telefonos-titulo {
    font-weight: $font-weight-medium;
    color: $color-secondary-dark;
  }

  &__telefonos-telefono {
    color: $color-grey-3;
    text-decoration: none;
    &:hover {
      color: $color-grey-2;
    }
  }

  &__horario {
    font-size: $font-size-small;
    strong {
      color: $color-secondary-dark;
    }
  }
}
