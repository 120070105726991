.presentacion {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
  }

  &__img {
    border-radius: 1rem;
    aspect-ratio: 16 / 11;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }

  &__col2 {
    align-self: center;
    padding-left: 5rem;
    @media screen and (max-width: 991px) {
      padding-left: 2rem;
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  &__descripcion {
    color: $color-grey-3;
    font-size: $font-size-big;
  }
}

.galeria-empresa {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  @include pad-l-r;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    .galeria-empresa__item:nth-last-child(1) {
      grid-column: 1 / span 2;
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    gap: 2px;
    .galeria-empresa__item:nth-last-child(1) {
      grid-column: 1 / span 1;
    }
  }

  &__item {
    &:nth-child(1) {
      @media screen and (min-width: 992px) {
        grid-column: 2 / 4;
        grid-row: 1 / 3;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      margin-bottom: 2px;
    }
  }
}
