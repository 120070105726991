body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;
}

a {
  color: $color-primary;
  text-decoration: none;
  transition: $trans;
  &:hover {
    text-decoration: none;
    transition: $trans;
  }
}
